import { usePathname } from "next/navigation";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

// Criação do contexto com tipo
const FormContext = createContext<
  | {
      inputValue: string;
      options: string[];
      setInputValue: Dispatch<SetStateAction<string>>;
      setOptions: Dispatch<SetStateAction<string[]>>;
    }
  | undefined
>(undefined);

export const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error(
      "useAppContext deve ser usado dentro de um AppContext.Provider"
    );
  }
  return context;
};

// Componente Provider para envolver a aplicação e fornecer o contexto
export const FormVendaContext = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  // Definir o estado inicial com tipos
  const pathname = usePathname();
  const [path, setPath] = useState(pathname);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<string[]>([]);

  useEffect(() => {
    if (pathname !== path) {
      setPath(pathname);
      setInputValue("");
    }
  }, [pathname]);

  return (
    <FormContext.Provider
      value={{ inputValue, setInputValue, setOptions, options }}
    >
      {children}
    </FormContext.Provider>
  );
};
