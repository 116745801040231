import React from "react";
import styles from "@/styles/desktop/Footer.module.scss";
import CustomLink from "@/app/components/CustomLink";

type NavigationItem = {
  name: string;
  href: string;
  target?: string;
};

type NavigationSectionProps = {
  sections: {
    title: string;
    items: NavigationItem[];
  }[];
};

const NavigationSection: React.FC<NavigationSectionProps> = ({ sections }) => (
  <nav className={styles.navigationSection}>
    {sections.map((section, index) => (
      <div key={section.title + index} className={styles.section}>
        <h2 className={styles.title}>{section.title}</h2>
        <ul className={styles.linksList}>
          {section.items.map((item, itemIndex) => (
            <li key={item.name + itemIndex}>
              <CustomLink
                href={item.href}
                target={item.target}
                className={`${styles.link} ${
                  item.target ? styles.external : ""
                }`}
              >
                {item.name}
              </CustomLink>
            </li>
          ))}
        </ul>
      </div>
    ))}
  </nav>
);

export default NavigationSection;
