"use client";
import { useEffect, useState } from "react";
import { HeaderDesktop } from "./Desktop";
import { HeaderMobile } from "./Mobile";
import styles from "@/styles/UI/Header.module.scss";

export const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    const controlNavbar = () => {
      setLastScrollY(window.scrollY);
    };

    window.addEventListener("scroll", controlNavbar);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [lastScrollY]);

  return (
    <header className={styles.header}>
      {isMobile ? <HeaderMobile /> : <HeaderDesktop />}
    </header>
  );
};
