import { actualSite, BRAND_NAMES, formatName } from "@/app/utils/utils";
import { useState, FormEvent, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { PatternFormat } from "react-number-format";
import chatbotIcon from "../../../../public/icon_whatsapp_chatboot.svg";
import { homeFlow } from "./flows/homeFlow";
import { yarisFlow } from "./flows/yarisFlow";
import { defaultFlow } from "./flows/defaultFlow";
import type { Message, Flow, FormData, InputType } from "./flows/types";

const AVAILABLE_CARS = [
  "COROLLA",
  "COROLLA CROSS",
  "HILUX",
  "SW4",
  "RAV4",
  "YARIS",
  "YARIS SEDAN",
];

export default function ChatBot() {
  const { pathname, lojas } = actualSite();
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [currentFlow, setCurrentFlow] = useState<Flow>(defaultFlow);
  const [formData, setFormData] = useState<FormData>({
    name: "",
    phone: "",
    email: "",
    cpf: "",
    model: "",
    dealer: "",
  });
  const [messages, setMessages] = useState<Message[]>([
    {
      type: "bot",
      content: currentFlow.steps[1].message as string,
    },
  ]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const selectRef = useRef<HTMLSelectElement>(null);
  const patternRef = useRef<HTMLInputElement>(null);
  
  // Determina qual fluxo usar baseado no pathname
  useEffect(() => {
    if (pathname === "/") {
      setCurrentFlow(homeFlow);
    } else if (pathname.includes("/yaris")) {
      setCurrentFlow(yarisFlow);
    } else {
      setCurrentFlow(defaultFlow);
    }
  }, [pathname]);
  
  // Scroll para a última mensagem
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);
  
  // Novo useEffect para gerenciar o foco
  useEffect(() => {
    const focusInput = () => {
      const currentStep = currentFlow.steps[step];
      
      // Pequeno timeout para garantir que o DOM foi atualizado
      setTimeout(() => {
        switch (currentStep.inputType) {
          case "carSelect":
          case "yarisSelect":
          case "dealerSelect":
            selectRef.current?.focus();
            break;
          case "phone":
          case "cpf":
            patternRef.current?.focus();
            break;
          default:
            inputRef.current?.focus();
        }
      }, 100);
    };
    
    focusInput();
  }, [step, currentFlow]);
  
  // Adicionar função auxiliar para determinar category e product title
  const determinePageInfo = (pathname: string) => {
    // Remove a primeira barra e divide o caminho
    const pathParts = pathname.slice(1).split("/");
    
    if (pathParts[0] === "venda-direta") {
      return {
        categoryTitle: `Venda Direta - ${
              pathParts[1].charAt(0).toUpperCase() + pathParts[1].slice(1)
        }`,
        productTitle: "Sem informação - Lead Whatsapp",
      };
    }
    
    if (pathParts[0] === "servicos") {
      return {
        categoryTitle: `Serviços - ${
              pathParts[1].charAt(0).toUpperCase() + pathParts[1].slice(1)
        }`,
        productTitle: "Sem informação - Lead Whatsapp",
      };
    }
    
    // Para a home
    if (pathname === "/") {
      return {
        categoryTitle: "Veiculos Novos",
        productTitle: formData.model || "HOME",
      };
    }
    
    // Para páginas de carros específicos
    if (
          pathParts.length === 1 &&
          !pathParts[0].includes("servicos") &&
          !pathParts[0].includes("venda-direta")
    ) {
      return {
        categoryTitle: "Veiculos Novos",
        productTitle: pathname.slice(1).toUpperCase(),
      };
    }
    
    // Caso padrão
    return {
      categoryTitle: "Veiculos Novos",
      productTitle: pathname.slice(1).toUpperCase(),
    };
  };
  
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      const currentStep = currentFlow.steps[step];
      const isValid = currentStep.validate(currentMessage);
      
      if (!isValid) {
        setMessages((prev) => [
          ...prev,
          {
            type: "error",
            content: currentStep.errorMessage,
          },
        ]);
        setIsSubmitting(false);
        return;
      }
      
      // Atualiza o formData antes de qualquer outra operação
      switch (currentStep.inputType) {
        case "text":
          setFormData((prev) => ({ ...prev, name: currentMessage }));
          break;
        case "phone":
          setFormData((prev) => ({
            ...prev,
            phone: currentMessage.replace(/\D/g, ""),
          }));
          break;
        case "email":
          setFormData((prev) => ({ ...prev, email: currentMessage }));
          break;
        case "cpf":
          setFormData((prev) => ({
            ...prev,
            cpf: currentMessage.replace(/\D/g, ""),
          }));
          break;
        case "carSelect":
        case "yarisSelect":
          setFormData((prev) => ({ ...prev, model: currentMessage }));
          break;
        case "dealerSelect":
          setFormData((prev) => ({ ...prev, dealer: currentMessage }));
          break;
      }
      
      // Adiciona a mensagem do usuário
      setMessages((prev) => [
        ...prev,
        {
          type: "user",
          content: currentMessage,
        },
      ]);
      
      // Se for o último step, aguarda a atualização do estado antes de enviar
      if (step === currentFlow.totalSteps) {
        // Aguarda a atualização do estado
        await new Promise((resolve) => setTimeout(resolve, 0));
        
        const updatedFormData = {
          ...formData,
          [currentStep.inputType === "cpf" ? "cpf" : ""]:
                currentMessage.replace(/\D/g, ""),
        };
        
        const urlParams = new URLSearchParams(window.location.search);
        const utmParams = urlParams.toString();
        
        // Determina o modelo baseado no fluxo e seleção
        const modelValue =
              pathname === "/"
                    ? updatedFormData.model
                    : pathname.includes("/yaris")
                          ? updatedFormData.model
                          : pathname.slice(1).toUpperCase();
        
        // Determina o valor de page
        const pageValue =
              pathname === "/"
                    ? "HOME"
                    : pathname.includes("/yaris")
                          ? updatedFormData.model
                          : pathname.slice(1).toUpperCase();
        
        const { categoryTitle, productTitle } = determinePageInfo(pathname);
        
        const response = await fetch("/api/submitForm", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: updatedFormData.name,
            email: updatedFormData.email,
            cpf: updatedFormData.cpf,
            phone: updatedFormData.phone,
            params: utmParams,
            consorcio: false,
            registrationNumber: updatedFormData.cpf,
            team: BRAND_NAMES.ALL_CAPS,
            locationId: 1,
            model: modelValue,
            page: pageValue,
            wpp: true,
            chatbot:
                  pathname === "/" ? "HOME" : pathname.slice(1).toUpperCase(),
            categoryId: 1,
            brandId: 67260,
            dealer: updatedFormData.dealer || "Whatsapp",
            contactMethod: "WHATSAPP",
            categoryTitle: categoryTitle,
            productTitle: productTitle,
            service: pathname.includes("/servicos/pos-venda")
                  ? "Atendimento Pós-Venda"
                  : undefined,
          }),
        });
        
        if (!response.ok) {
          throw new Error("Erro ao enviar formulário");
        }
        
        setFormSubmitted(true);
        setMessages((prev) => [
          ...prev,
          {
            type: "bot",
            content:
                  "✅ Seus dados foram enviados com sucesso! Você será redirecionado para o WhatsApp...",
          },
        ]);
        
        // Redireciona para o WhatsApp
        const whatsappNumber = pathname.includes("/servicos/pos-venda")
              ? BRAND_NAMES.POS_NUMBER
              : BRAND_NAMES.NORMAL_NUMBER;
        
        window.open(
              `https://wa.me/${whatsappNumber}?text=Olá! Me chamo ${updatedFormData.name}`,
              "_blank"
        );
      } else {
        // Se não for o último step, continua com o fluxo normal
        const nextStep = step + 1;
        const nextMessage =
              typeof currentFlow.steps[nextStep].message === "function"
                    ? currentFlow.steps[nextStep].message(formData.name)
                    : currentFlow.steps[nextStep].message;
        
        setMessages((prev) => [
          ...prev,
          {
            type: "bot",
            content: nextMessage as string,
          },
        ]);
        setStep(nextStep);
      }
      
      setCurrentMessage("");
    } catch (error) {
      console.error("Erro:", error);
      setMessages((prev) => [
        ...prev,
        {
          type: "error",
          content: "❌ Ocorreu um erro ao processar sua solicitação.",
        },
      ]);
    } finally {
      setIsSubmitting(false);
    }
  };
  
  // Renderiza o input apropriado baseado no tipo
  const renderInput = (inputType: InputType) => {
    switch (inputType) {
      case "phone":
        return (
              <PatternFormat
                    getInputRef={patternRef}
                    value={currentMessage}
                    onValueChange={({ value }) => setCurrentMessage(value)}
                    format="(##) #####-####"
                    mask="_"
                    className={inputClassName}
              />
        );
      case "cpf":
        return (
              <PatternFormat
                    getInputRef={patternRef}
                    value={currentMessage}
                    onValueChange={({ value }) => setCurrentMessage(value)}
                    format="###.###.###-##"
                    mask="_"
                    className={inputClassName}
              />
        );
      case "carSelect":
        return (
              <select
                    ref={selectRef}
                    value={currentMessage}
                    onChange={(e) => setCurrentMessage(e.target.value)}
                    className={inputClassName}
              >
                <option value="">Selecione o modelo</option>
                {AVAILABLE_CARS.map((car) => (
                      <option key={car} value={car}>
                        {car}
                      </option>
                ))}
              </select>
        );
      case "yarisSelect":
        return (
              <select
                    ref={selectRef}
                    value={currentMessage}
                    onChange={(e) => setCurrentMessage(e.target.value)}
                    className={inputClassName}
              >
                <option value="">Selecione o modelo</option>
                <option value="YARIS SEDAN">Yaris Sedan</option>
                <option value="YARIS HATCH">Yaris Hatch</option>
              </select>
        );
      case "dealerSelect":
        return (
              <select
                    ref={selectRef}
                    value={currentMessage}
                    onChange={(e) => setCurrentMessage(e.target.value)}
                    className={inputClassName}
              >
                <option value="">Selecione a unidade</option>
                {lojas.map((loja) => (
                      <option key={loja.nome} value={formatName(loja.nome)}>
                        {formatName(loja.nome)}
                      </option>
                ))}
              </select>
        );
      default:
        return (
              <input
                    ref={inputRef}
                    type={inputType === "email" ? "email" : "text"}
                    value={currentMessage}
                    onChange={(e) => setCurrentMessage(e.target.value)}
                    className={inputClassName}
              />
        );
    }
  };
  
  const inputClassName =
        "w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-600 focus:border-transparent transition-all";
  
  return (
        <>
          <section className="flex flex-col fixed z-40 lg:bottom-10 lg:right-10 bottom-[calc(env(safe-area-inset-bottom)+20px)] right-5 w-[60px] h-[60px]">
            {isOpen ? (
                  <>
                    <main className="absolute lg:bottom-0 lg:right-0 flex flex-col overflow-hidden lg:rounded-xl lg:w-[360px] lg:h-[420px] max-lg:w-screen max-lg:h-[100dvh] max-lg:fixed max-lg:top-0 max-lg:left-0 max-lg:right-0 max-lg:bottom-0 max-lg:z-40 shadow-[3px_4px_12px_rgba(0,0,0,0.15)]">
                      <div className="flex overflow-hidden flex-col w-full h-full">
                        <header className="flex gap-9 justify-between items-center p-4 w-full leading-snug bg-red-600 text-stone-50">
                          <div className="flex gap-3 items-center self-stretch my-auto min-w-[240px]">
                            <img
                                  loading="lazy"
                                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/d61101d0a770b26b8c2cdee6d5616f8df88b8e2ce5c722f3c43afbb5e7c2473e?placeholderIfAbsent=true&apiKey=eac21aa640a54d679c8b4aff1d0cf286"
                                  alt="Chat assistant logo"
                                  className="object-contain shrink-0 self-stretch my-auto aspect-[1.49] w-[49px]"
                            />
                            <div className="flex flex-col self-stretch my-auto">
                              <h1 className="text-base font-semibold">
                                Assistente Virtual {BRAND_NAMES.DEFAULT}
                              </h1>
                              <p className="text-xs">Online agora</p>
                            </div>
                          </div>
                          <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/6f6988fb0ce3ac56ffc7af4c2b9df8644b88e3128e5b1d3f394d1660eec69eae?placeholderIfAbsent=true&apiKey=eac21aa640a54d679c8b4aff1d0cf286"
                                alt="Menu"
                                className="object-contain shrink-0 self-stretch my-auto aspect-[1.03] w-[31px] cursor-pointer"
                                onClick={() => setIsOpen(false)}
                          />
                        </header>
                        <section className="flex overflow-hidden flex-col flex-1 px-4 py-6 w-full bg-white">
                          <div className="flex flex-col gap-4 overflow-y-auto mb-4 flex-1 chat-container">
                            <AnimatePresence>
                              {messages.map((message, index) => (
                                    <motion.div
                                          key={index}
                                          initial={{ opacity: 0, y: 20 }}
                                          animate={{ opacity: 1, y: 0 }}
                                          exit={{ opacity: 0, y: -20 }}
                                          transition={{ duration: 0.3 }}
                                          className={`flex gap-1 items-start ${
                                                message.type === "user" ? "flex-row-reverse" : ""
                                          }`}
                                    >
                                      <div
                                            className={`p-3 rounded-lg max-w-[80%] ${
                                                  message.type === "user"
                                                        ? "bg-gray-500 text-white"
                                                        : message.type === "error"
                                                              ? "bg-red-100 text-red-600 border border-red-200"
                                                              : "bg-gray-900 text-white"
                                            }`}
                                      >
                                        {message.content}
                                        {message.type === "error" && (
                                              <button
                                                    onClick={() => setStep(1)}
                                                    className="mt-2 px-3 py-1 bg-red-500 text-white rounded text-sm hover:bg-red-600 transition-colors"
                                              >
                                                Reiniciar conversa
                                              </button>
                                        )}
                                      </div>
                                    </motion.div>
                              ))}
                            </AnimatePresence>
                            <div ref={messagesEndRef} />
                          </div>
                          <div className="max-lg:pb-[calc(env(safe-area-inset-bottom)+20px)]">
                            {!formSubmitted && (
                                  <form onSubmit={handleSubmit} className="text-black">
                                    {renderInput(currentFlow.steps[step].inputType)}
                                    <button
                                          type="submit"
                                          disabled={isSubmitting}
                                          className={`mt-2 px-4 py-2 rounded w-full transition-colors ${
                                                isSubmitting
                                                      ? "bg-gray-400 cursor-not-allowed"
                                                      : "bg-red-600 hover:bg-red-700"
                                          } text-white`}
                                    >
                                      {isSubmitting ? (
                                            <span className="flex items-center gap-2">
                              <svg
                                    className="animate-spin h-5 w-5"
                                    viewBox="0 0 24 24"
                              >
                                <circle
                                      className="opacity-25"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="currentColor"
                                      strokeWidth="4"
                                />
                                <path
                                      className="opacity-75"
                                      fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                />
                              </svg>
                              Enviando...
                            </span>
                                      ) : (
                                            "Enviar"
                                      )}
                                    </button>
                                  </form>
                            )}
                          </div>
                        </section>
                      </div>
                    </main>
                  </>
            ) : (
                  <img
                        loading="lazy"
                        src={chatbotIcon.src}
                        alt="Assistente virtual"
                        className="object-contain w-[60px] h-[60px] cursor-pointer hover:scale-105 transition-transform"
                        onClick={() => setIsOpen(true)}
                  />
            )}
          </section>
        </>
  );
}
