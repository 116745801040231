import Image from "next/image";
import React from "react";
import styles from "@/styles/desktop/Footer.module.scss";

type HeaderProps = {
  logoSrc: string;
  brandName: string;
  iconSrcs: string[];
};

const Header: React.FC<HeaderProps> = ({ logoSrc, brandName, iconSrcs }) => (
  <header className={styles.header}>
    <div className={styles.logoContainer}>
      <div className={styles.logoWrapper}>
        <Image
          width={400}
          height={400}
          loading="lazy"
          src={logoSrc}
          alt="Company logo"
          className={styles.logo}
        />
      </div>
      <div className={styles.brandName}>{brandName}</div>
    </div>
    <nav className={styles.socialNav}>
      {iconSrcs.map((src, index) => (
        <Image
          width={400}
          height={400}
          key={"iconsrc" + src + index}
          loading="lazy"
          src={src}
          alt={`Navigation icon ${index + 1}`}
          className={styles.socialIcon}
        />
      ))}
    </nav>
  </header>
);

export default Header;
