"use client";
import { useState, useRef, useEffect } from "react";
import { BsChevronDown, BsSun, BsMoon } from "react-icons/bs";
import { BiLinkExternal } from "react-icons/bi";
import Image from "next/image";
import gsap from "gsap";
import { LinksNavigation, NavigationLines } from "./NavigationInfo";
import CustomLink from "@/app/components/CustomLink";
import styles from "@/styles/UI/Header.module.scss";
import { BRAND_NAMES } from "@/app/utils/utils";
import { RiAlertFill } from "react-icons/ri";
import AlertaModal from "../AlertaModal/AlertaModal";

export const HeaderDesktop = () => {
  const [activeDropdown, setActiveDropdown] = useState<
    LinksNavigation | undefined
  >();
  const [activeCategory, setActiveCategory] = useState<string>("Passeio");
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const isAnimating = useRef(false);
  const modelsContainerRef = useRef<HTMLDivElement>(null);

  const animateDropdown = (show: boolean, callback?: () => void) => {
    if (!dropdownRef.current || isAnimating.current) return;

    isAnimating.current = true;

    if (show) {
      // Configurar estado inicial
      gsap.set(dropdownRef.current, {
        opacity: 0,
        y: -20,
        display: "block",
      });

      // Animar abertura
      gsap.to(dropdownRef.current, {
        opacity: 1,
        y: 0,
        duration: 0.3,
        ease: "power2.out",
        onComplete: () => {
          isAnimating.current = false;
          callback?.();
        },
      });
    } else {
      // Animar fechamento
      gsap.to(dropdownRef.current, {
        opacity: 0,
        y: -20,
        duration: 0.3,
        ease: "power2.in",
        onComplete: () => {
          gsap.set(dropdownRef.current, { display: "none" });
          isAnimating.current = false;
          callback?.();
        },
      });
    }
  };

  const handleDropdownClick = (line: LinksNavigation) => {
    // Se tiver link direto, não abre dropdown
    if (line.link) {
      return;
    }

    if (activeDropdown?.name === line.name) {
      // Fechar dropdown atual
      animateDropdown(false, () => setActiveDropdown(undefined));
    } else if (!activeDropdown) {
      // Abrir novo dropdown
      setActiveDropdown(line);
    } else {
      // Fechar dropdown atual e abrir novo
      animateDropdown(false, () => setActiveDropdown(line));
    }
  };

  useEffect(() => {
    if (activeDropdown) {
      animateDropdown(true);
    }
  }, [activeDropdown]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        headerRef.current &&
        !headerRef.current.contains(event.target as Node) &&
        activeDropdown
      ) {
        animateDropdown(false, () => {
          setActiveDropdown(undefined);
          setActiveCategory("");
        });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeDropdown]);

  useEffect(() => {
    if (
      activeDropdown?.groups &&
      activeDropdown.groups.length > 0 &&
      activeCategory === ""
    ) {
      setActiveCategory(activeDropdown.groups[0].name);
    }
  }, [activeDropdown, activeCategory]);

  useEffect(() => {
    if (activeDropdown?.name === "Novos") {
      setActiveCategory("Passeio");
    }
  }, [activeDropdown]);

  const renderNovosContent = (line: LinksNavigation) => (
    <div className={styles.categoriesContainer}>
      <div className={styles.categoriesHeader}>
        {line.groups?.map((group) => (
          <button
            key={group.name}
            className={`${styles.categoryButton} ${
              styles[`category-${group.name.toLowerCase()}`]
            } ${activeCategory === group.name ? styles.active : ""}`}
            onClick={() => setActiveCategory(group.name)}
          >
            {group.name}
          </button>
        ))}
      </div>

      <div className={styles.modelsContainer} ref={modelsContainerRef}>
        {line.groups?.map((group) => (
          <div
            key={group.name}
            className={`${styles.modelsGrid} ${
              activeCategory === group.name ? styles.visible : styles.hidden
            }`}
          >
            {group.itens.map((item, index) => (
              <CustomLink
                key={index}
                href={item.link}
                className={styles.modelCard}
                onClick={() => handleDropdownClick(line)}
                target={item.target}
              >
                {item.src && (
                  <div className={styles.imageContainer}>
                    <Image
                      src={item.src}
                      alt={item.name}
                      width={200}
                      height={120}
                      className={styles.modelImage}
                    />
                  </div>
                )}
                <span className={styles.modelName}>{item.name}</span>
              </CustomLink>
            ))}
          </div>
        ))}
      </div>
    </div>
  );

  const renderDefaultContent = (line: LinksNavigation) => (
    <div className={styles.defaultDropdownContent}>
      {line.src && (
        <div className={styles.dropdownImage}>
          <Image
            src={line.src}
            alt={line.name}
            width={200}
            height={300}
            className={styles.navigationImage}
          />
        </div>
      )}
      {line.groups?.map((group) => (
        <div key={group.name} className={styles.dropdownGroup}>
          {group.name && <h3 className={styles.groupTitle}>{group.name}</h3>}
          <div className={styles.groupList}>
            {group.itens.map((item, index) => (
              <CustomLink
                key={index}
                href={item.link}
                className={styles.groupItem}
                onClick={() => handleDropdownClick(line)}
                target={item.target}
              >
                {item.name}
                {item.target === "_blank" && (
                  <BiLinkExternal className={styles.externalIcon} />
                )}
              </CustomLink>
            ))}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className={styles.container} ref={headerRef}>
      <div className={styles.logoContainer}>
        <CustomLink
          href="/"
          onClick={() => activeDropdown && handleDropdownClick(activeDropdown)}
        >
          <Image
            src={BRAND_NAMES.siteLogoMenu}
            alt="Kurumá"
            width={150}
            height={150}
          />
        </CustomLink>
      </div>

      <nav className={styles.navigation}>
        {NavigationLines.map((line) => (
          <div key={line.name} className={styles.navItem}>
            {line.link ? (
              <CustomLink href={line.link} className={styles.navButton}>
                {line.name}
              </CustomLink>
            ) : (
              <>
                <button
                  className={styles.navButton}
                  onClick={() => handleDropdownClick(line)}
                >
                  {line.name}
                  <BsChevronDown
                    className={`${styles.chevron} ${
                      activeDropdown?.name === line.name
                        ? styles.rotateIcon
                        : ""
                    }`}
                  />
                </button>

                {activeDropdown?.name === line.name && (
                  <div ref={dropdownRef} className={styles.dropdownMenu}>
                    {line.name === "Novos"
                      ? renderNovosContent(line)
                      : renderDefaultContent(line)}
                  </div>
                )}
              </>
            )}
          </div>
        ))}
        <button
          onClick={() => setIsAlertModalOpen(true)}
          className={styles.alertButton}
        >
          <RiAlertFill className={`${styles.alertIcon} text-red-600`} />
          <span className={`${styles.alertText} animate-pulse`}>
            Alerta aos consumidores
          </span>
        </button>
      </nav>

      {isAlertModalOpen && (
        <AlertaModal onClose={() => setIsAlertModalOpen(false)} />
      )}
    </div>
  );
};
