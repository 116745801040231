"use client";
import { useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { RiAlertFill } from "react-icons/ri";

export default function AlertaModal({ onClose }: { onClose: () => void }) {
  useEffect(() => {
    // Previne o scroll do body quando o modal está aberto
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <>
      <div
        className="fixed inset-0 bg-black/50 backdrop-blur-sm z-[999]"
        onClick={onClose}
      />
      <div className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[90vw] max-w-[600px] bg-white rounded-xl shadow-xl z-[1000] max-h-[90vh] overflow-y-auto">
        <div className="p-6 border-b border-neutral-200">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-3">
              <RiAlertFill size={28} className="text-red-600" />
              <h2 className="text-2xl font-semibold text-red-600">
                Alerta aos consumidores
              </h2>
            </div>
            <button
              onClick={onClose}
              className="text-neutral-500 hover:text-neutral-700 transition-colors"
            >
              <AiOutlineClose size={24} />
            </button>
          </div>
        </div>

        <div className="p-6 text-neutral-700 space-y-4">
          <p>
            O Grupo Águia Branca faz um alerta aos consumidores de veículos de
            sua marca para os golpes que vêm sendo aplicados em várias regiões
            do país, nos quais estelionatários anunciam por diversos meios de
            comunicação, especialmente nos ambientes digitais, a venda de
            veículos, peças e serviços diretamente de funcionários desta, por
            valor bem abaixo do valor de mercado e em condições de pagamento
            muito atraentes.
          </p>

          <p>
            Quando o consumidor entra em contato, os estelionatários
            identificam-se como funcionários da empresa e induzem o consumidor a
            adquirir os produtos como sendo um negócio "imperdível", bastando,
            para garantir a reserva, que seja realizado depósito ou
            transferência eletrônica uma parte do valor.
          </p>

          <p>
            Além disso, na tentativa de conferirem maior veracidade ao golpe, os
            estelionatários emitem notas fiscais, recibos e contratos com a
            logomarca da montadora, como se o negócio fosse entabulado entre o
            consumidor e o funcionário com a anuência da concessionária.
          </p>

          <p>
            Diante do aumento das tentativas de fraude e reclamações, divulgamos
            a seguir orientações fundamentais acerca da aquisição de produtos e
            serviços, na tentativa de alertar os seus consumidores, potenciais
            vítimas do golpe aplicado pelos estelionatários:
          </p>

          <ul className="list-disc pl-6 space-y-2">
            <li>
              Compare o preço anunciado com o preço do mesmo veículo e modelo,
              com iguais características nos sites distribuidores autorizado,
              site oficial das montadoras e/ou pelo Portal da fundação
              fipe.org.br;
            </li>
            <li>
              Desconfie de anúncios que oferecem grandes vantagens financeiras;
            </li>
            <li>
              Jamais efetue o pagamento de qualquer quantia referente à
              aquisição de um veículo, peças ou acessórios antes de pesquisar a
              idoneidade do anunciante (verificar dados da empresa e do
              anunciante, CNPJ, localização…);
            </li>
            <li>
              Jamais efetue o pagamento de qualquer quantia referente à compra
              do veículo sem examinar pessoalmente em uma concessionária física;
            </li>
          </ul>

          <p>
            Em caso de dúvida, contate o Serviço de Atendimento ao Consumidor no
            e-mail: compliance.comercio@aguiabranca.com.br
          </p>
        </div>
      </div>
    </>
  );
}
