import React, { useContext } from "react";
import CustomLink from "./CustomLink";
import styles from "/styles/CMS/Header.module.scss";
import AuthContext from "@/app/contexts/AuthContext";
import { useRouter } from "next/router";
import { BRAND_NAMES } from "@/app/utils/utils";
const HeaderCMS: React.FC<any> = ({ setNewBanner }) => {
  const authContext = useContext(AuthContext);
  const router = useRouter();

  if (!authContext) {
    throw new Error("AuthContext must be used within an AuthProvider");
  }

  const { logout } = authContext;

  const getLinkClass = (path: string) => {
    return router.pathname === path ? `${styles.activeLink}` : "";
  };

  return (
    <header>
      <div className={styles.menu}>
        <CustomLink onClick={() => setNewBanner(false)} href="/cms/dashboard">
          <img
            src={`/images/logo_menu_${BRAND_NAMES.LOWERCASE_PLAIN}.svg`}
            alt="CMS TOYOTA"
            width={194}
            height={56}
          />
        </CustomLink>

        <button type="button" onClick={logout}>
          <img src="/img/byd/cms/logout.svg" alt="" width={23} height={21} />
          Sair
        </button>
      </div>
    </header>
  );
};

export default React.memo(HeaderCMS);
