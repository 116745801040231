"use client";
import { useState, useRef, useEffect } from "react";
import { BsSun, BsMoon, BsChevronDown } from "react-icons/bs";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { BiLinkExternal } from "react-icons/bi";
import { RiAlertFill } from "react-icons/ri";
import gsap from "gsap";
import CustomLink from "@/app/components/CustomLink";
import styles from "@/styles/UI/HeaderMobile.module.scss";
import { NavigationLines } from "./NavigationInfo";
import Image from "next/image";
import { BRAND_NAMES } from "@/app/utils/utils";
import AlertaModal from "../AlertaModal/AlertaModal";

export const HeaderMobile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const isAnimating = useRef(false);

  const animateDropdown = (show: boolean) => {
    if (!dropdownRef.current || isAnimating.current) return;
    isAnimating.current = true;

    if (show) {
      gsap.set(dropdownRef.current, {
        opacity: 0,
        y: -20,
        display: "flex",
      });

      gsap.to(dropdownRef.current, {
        opacity: 1,
        y: 0,
        duration: 0.3,
        ease: "power2.out",
        onComplete: () => {
          isAnimating.current = false;
        },
      });
    } else {
      gsap.to(dropdownRef.current, {
        opacity: 0,
        y: -20,
        duration: 0.3,
        ease: "power2.in",
        onComplete: () => {
          gsap.set(dropdownRef.current, { display: "none" });
          isAnimating.current = false;
        },
      });
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    animateDropdown(!isOpen);
  };

  const toggleSection = (sectionName: string) => {
    setActiveSection(activeSection === sectionName ? null : sectionName);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  return (
    <div className={styles.mobileContainer}>
      <div className={styles.alertHeader}>
        <button
          onClick={() => setIsAlertModalOpen(true)}
          className={styles.alertButton}
        >
          <RiAlertFill className={styles.alertIcon} />
          <span className={styles.alertText}>Alerta aos consumidores</span>
        </button>
      </div>

      <div className={styles.mobileHeader}>
        <CustomLink href="/" onClick={() => isOpen && toggleMenu()}>
          <Image
            src={BRAND_NAMES.siteLogoMenu}
            alt="Kurumá"
            width={150}
            height={150}
          />
        </CustomLink>

        <div className={styles.mobileActions}>
          <button
            className={styles.menuButton}
            onClick={toggleMenu}
            aria-label="Toggle menu"
          >
            {!isOpen ? (
              <AiOutlineMenu size={24} />
            ) : (
              <AiOutlineClose size={24} />
            )}
          </button>
        </div>
      </div>

      <div ref={dropdownRef} className={styles.mobileDropdown}>
        <nav className={styles.mobileNav}>
          {NavigationLines.map((line) => (
            <div key={line.name} className={styles.navSection}>
              {line.link ? (
                <CustomLink href={line.link} className={styles.sectionButton}>
                  {line.name}
                </CustomLink>
              ) : (
                <>
                  <button
                    className={`${styles.sectionButton} ${
                      activeSection === line.name ? styles.active : ""
                    }`}
                    onClick={() => toggleSection(line.name)}
                  >
                    {line.name}
                    <BsChevronDown
                      className={`${styles.chevron} ${
                        activeSection === line.name ? styles.rotated : ""
                      }`}
                    />
                  </button>

                  {activeSection === line.name && line.groups && (
                    <div className={styles.sectionContent}>
                      {line.groups.map((group) => (
                        <div key={group.name} className={styles.groupContainer}>
                          {group.name && (
                            <h3 className={styles.groupTitle}>{group.name}</h3>
                          )}
                          <div
                            className={`${styles.groupItems} ${
                              line.name === "Novos" ? styles.gridItems : ""
                            }`}
                          >
                            {group.itens.map((item, index) => (
                              <CustomLink
                                key={`${item.name}-${index}`}
                                href={item.link}
                                className={`${styles.itemLink} ${
                                  line.name === "Novos"
                                    ? styles.gridItemLink
                                    : ""
                                }`}
                                onClick={toggleMenu}
                                target={item.target}
                              >
                                {line.name === "Novos" && item.src && (
                                  <div className={styles.imageContainer}>
                                    <Image
                                      src={item.src}
                                      alt={item.name}
                                      width={140}
                                      height={80}
                                      className={styles.modelImage}
                                    />
                                  </div>
                                )}
                                <span className={styles.itemName}>
                                  {item.name}
                                </span>
                                {item.target === "_blank" && (
                                  <BiLinkExternal size={16} />
                                )}
                              </CustomLink>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          ))}
        </nav>
      </div>

      {isAlertModalOpen && (
        <AlertaModal onClose={() => setIsAlertModalOpen(false)} />
      )}
    </div>
  );
};
