import Image from "next/image";
import React from "react";
import styles from "@/styles/desktop/Footer.module.scss";

type FooterProps = {
  logoSrc: string;
  companyInfo: string;
  copyrightInfo: string;
  safetyLogoSrc: string;
  safetyText: string;
};

const Footer: React.FC<FooterProps> = ({
  logoSrc,
  companyInfo,
  copyrightInfo,
  safetyLogoSrc,
  safetyText,
}) => (
  <footer className={styles.footer}>
    <div className={styles.container}>
      <div className={styles.companyInfo}>
        <Image
          width={400}
          height={400}
          loading="lazy"
          src={logoSrc}
          alt="Company logo"
          className={styles.logo}
        />
        <div className={styles.info}>{companyInfo}</div>
        <div className={styles.copyright}>{copyrightInfo}</div>
      </div>
      <div className={styles.safety}>
        <Image
          width={400}
          height={400}
          loading="lazy"
          src={safetyLogoSrc}
          alt="Safety logo"
          className={styles.logo}
        />
        <div className={styles.text}>{safetyText}</div>
      </div>
    </div>
  </footer>
);

export default Footer;
