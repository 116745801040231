import { Step, Flow } from "./types";

export const yarisFlow: Flow = {
  steps: {
    1: {
      message:
        "Oi, que bom ver você por aqui! 😄 Antes de começarmos, poderia me informar seu NOME COMPLETO?",
      validate: (value: string) => {
        const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ]+ [A-Za-zÀ-ÖØ-öø-ÿ ]+$/;
        return nameRegex.test(value);
      },
      errorMessage: "Por favor, digite seu nome completo (nome e sobrenome)",
      inputType: "text",
    },
    2: {
      message: (name: string) =>
        `Obrigado ${name}! Agora, por favor, me informe seu número de TELEFONE com DDD:`,
      validate: (value: string) => value.replace(/\D/g, "").length === 11,
      errorMessage: "O telefone deve estar no formato (99) 99999-9999",
      inputType: "phone",
    },
    3: {
      message: "Ótimo! Agora preciso do seu EMAIL:",
      validate: (value: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
      errorMessage: "Digite um email válido",
      inputType: "email",
    },
    4: {
      message: "Por favor, selecione o modelo do Yaris de seu interesse:",
      validate: (value: string) => value.length > 0,
      errorMessage: "Por favor, selecione um modelo do Yaris",
      inputType: "yarisSelect",
    },
    5: {
      message: "Agora, selecione a unidade de sua preferência:",
      validate: (value: string) => value.length > 0,
      errorMessage: "Por favor, selecione uma unidade",
      inputType: "dealerSelect",
    },
    6: {
      message: "Por último, me informe seu CPF (apenas números):",
      validate: (value: string) => value.replace(/\D/g, "").length === 11,
      errorMessage: "O CPF deve conter 11 dígitos válidos",
      inputType: "cpf",
    },
  },
  totalSteps: 6,
};
