import { actualSite, BRAND_NAMES } from "@/app/utils/utils";
import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import NavigationSection from "./NavigationSection";
import styles from "@/styles/desktop/Footer.module.scss";

const { siteDefaultName } = actualSite();

const navigationSections = [
  {
    title: "NOVOS",
    items: [
      { name: "Yaris", href: "/yaris" },
      { name: "Corolla", href: "/corolla" },
      { name: "Hilux", href: "/hilux" },
      { name: "SW4", href: "/sw4" },
      { name: "RAV4", href: "/rav4" },
      { name: "Corolla Cross", href: "/corolla-cross" },
    ],
  },
  {
    title: "SEMINOVOS",
    items: [
      {
        name: "Seminovos Certificados",
        href: BRAND_NAMES.SEMINOVOS_CERTIFICADO_URL,
        target: "_blank",
      },
      {
        name: "Site Seminovos",
        href: BRAND_NAMES.SEMINOVOS_SITE_URL,
        target: "_blank",
      },
    ],
  },
  {
    title: "VENDAS DIRETAS",
    items: [
      {
        name: "Frotista",
        href: "/venda-direta/frotista",
      },
      {
        name: "Pessoa com Deficiência",
        href: "/venda-direta/pessoa-com-deficiencia",
      },
      {
        name: "Produtor Rural",
        href: "/venda-direta/produtor-rural",
      },
      {
        name: "Taxista",
        href: "/venda-direta/taxista",
      },
      {
        name: "Governo",
        href: "/venda-direta/governo",
      },
    ],
  },
  {
    title: "SERVIÇOS",
    items: [
      {
        name: "Pós-Vendas",
        href: "/servicos/pos-venda",
      },
      {
        name: "Ciclo Toyota",
        href: "/servicos/ciclo-toyota",
      },
      {
        name: "Consórcio",
        href: "/servicos/consorcio",
      },
      {
        name: "Peças",
        href: "/servicos/pecas",
      },
      {
        name: "Garantia Toyota",
        href: "/garantiatoyota",
      },
      {
        name: "Agendamento",
        href: "/servicos/agendamento",
      },
    ],
  },
  {
    title: `SOBRE A ${BRAND_NAMES.UPPERCASE}`,
    items: [
      {
        name: "História e Demonstrativos",
        href: "/sobre",
      },
      // {
      //   name: "Serviço de Atendimento ao Consumidor",
      //   href: "",
      //   target: "_blank",
      // },
      {
        name: "Trabalhe Conosco",
        href: "https://grupoaguiabranca.empregare.com/pt-br",
        target: "_blank",
      },
      {
        name: "Canal Compliance",
        href: "https://compliancedivisaocomercio.com.br",
        target: "_blank",
      },
      {
        name: "Portal de Privacidade",
        href: "https://privacidadecomercio.aguiabranca.com.br",
        target: "_blank",
      },
    ],
  },
];

export const LayoutFooter: React.FC = () => (
  <div className={styles.footerContainer}>
    <Header
      logoSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/3efe153f24f066d7b20f980a4a8a805ba5d8662f9bd2a50de5bd917bb214d9ac?apiKey=eac21aa640a54d679c8b4aff1d0cf286&"
      brandName={siteDefaultName}
      iconSrcs={[
        "https://sablobsitestoyotaprd.blob.core.windows.net/images/SVG/item=face,%20status=default.svg",
        "https://sablobsitestoyotaprd.blob.core.windows.net/images/SVG/item=insta,%20status=default.svg",
        "https://sablobsitestoyotaprd.blob.core.windows.net/images/SVG/item=linke,%20status=default.svg",
        "https://sablobsitestoyotaprd.blob.core.windows.net/images/SVG/item=youtube,%20status=default.svg",
      ]}
    />
    <NavigationSection sections={navigationSections} />
    <Footer
      logoSrc="https://sablobsitestoyotaprd.blob.core.windows.net/images/SVG/logo_aguia_branca.svg"
      companyInfo="Divisão Comércio GAB – CNPJ 00.827.783/0001-81"
      copyrightInfo="Grupo Águia Branca ©2024 | Todos os direitos reservados"
      safetyLogoSrc="https://sablobsitestoyotaprd.blob.core.windows.net/images/SVG/ibama.svg.svg"
      safetyText="Desacelere. Seu bem maior é a vida."
    />
  </div>
);
