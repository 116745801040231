import { BRAND_NAMES } from "@/app/utils/utils";

interface PageMetadata {
  pagina: string;
  title: string;
  description: string;
}

export const pagesData: PageMetadata[] = [
  {
    pagina: "/",
    title: `${BRAND_NAMES.DEFAULT} | A sua concessionária Toyota ${BRAND_NAMES.STATE_RED}`,
    description: `O seu carro 0km está na ${BRAND_NAMES.DEFAULT} Toyota! SUV, sedan, hatch e picape com as melhores condições e ofertas imperdíveis. Confira!`,
  },
  {
    pagina: "/corolla-cross",
    title: `Corolla Cross 2025 na ${BRAND_NAMES.DEFAULT} Toyota`,
    description: `Descubra o novo Corolla Cross 2025 na ${BRAND_NAMES.DEFAULT} Toyota. Um SUV completo, com tecnologia avançada, conforto, e eficiência híbrida.`,
  },
  {
    pagina: "/yaris",
    title: `Conheça o Toyota Yaris na ${BRAND_NAMES.DEFAULT} Toyota`,
    description: `Seja na versão hatch ou sedã, o Yaris combina estilo, tecnologia e economia, perfeito para a vida urbana.`,
  },
  {
    pagina: "/corolla",
    title: `Corolla 2025 na ${BRAND_NAMES.DEFAULT}`,
    description: `Explore o Toyota Corolla 2025 na ${BRAND_NAMES.DEFAULT} Toyota. O sedã mais vendido do mundo, com design elegante, eficiência híbrida e tecnologia de ponta.`,
  },
  {
    pagina: "/rav4",
    title: `Toyota RAV4 2025 na ${BRAND_NAMES.DEFAULT} | SUV Híbrido e Tecnológico`,
    description: `Conheça o Toyota RAV4 2025 na ${BRAND_NAMES.DEFAULT} Toyota. SUV híbrido com desempenho excepcional, conforto premium e tecnologia avançada.`,
  },
  {
    pagina: "/sw4",
    title: `Toyota SW4 2025 na ${BRAND_NAMES.DEFAULT} | SUV Luxuoso com 10 Anos de Garantia`,
    description: `Descubra o Toyota SW4 na ${BRAND_NAMES.DEFAULT}. Um SUV off-road com capacidade off-road para quem não abre mão do conforto nas aventuras.`,
  },
  {
    pagina: "/hilux",
    title: `Toyota Hilux 2025 na ${BRAND_NAMES.DEFAULT} | A pick-up com 10 anos de garantia`,
    description: `Conheça a Toyota Hilux 2025 na ${BRAND_NAMES.DEFAULT}. A pick-up robusta e confiável com 10 anos de garantia, alto desempenho e resistência.`,
  },
  {
    pagina: "/servicos/ciclo-toyota",
    title: `Ciclo Toyota: Troque o Seu Toyota Sempre por um Novo na ${BRAND_NAMES.DEFAULT} Toyota`,
    description: `Conheça o Ciclo Toyota na ${BRAND_NAMES.DEFAULT}. Um plano de financiamento que facilita a aquisição do seu Toyota novo, com parcelas acessíveis e recompra garantida.`,
  },
  {
    pagina: "/servicos/pos-venda",
    title: `Pós-Venda: Manutenção e Peças Originais na ${BRAND_NAMES.DEFAULT} Toyota`,
    description: `Garanta a durabilidade do seu Toyota com o pós-venda ${BRAND_NAMES.DEFAULT}. Revisões completas, funilaria, peças originais e atendimento de excelência!`,
  },
  {
    pagina: "/venda-direta/pcd",
    title: `Carros PCD na ${BRAND_NAMES.DEFAULT} Toyota: Benefícios e Condições Especiais`,
    description: `Confira os benefícios e condições especiais para PCD na ${BRAND_NAMES.DEFAULT} Toyota. Veículos com descontos exclusivos e vantagem para você!`,
  },
  {
    pagina: "/venda-direta/frotista",
    title: `Condições Especiais para Frotistas na ${BRAND_NAMES.DEFAULT} Toyota`,
    description: `Aproveite os benefícios exclusivos para frotistas na ${BRAND_NAMES.DEFAULT} Toyota. Veículos robustos e confiáveis com condições especiais.`,
  },
  {
    pagina: "/venda-direta/produtor-rural",
    title: `Benefícios para Produtores Rurais é na ${BRAND_NAMES.DEFAULT} Toyota`,
    description: `Aproveite as condições especiais para produtores rurais na ${BRAND_NAMES.DEFAULT} Toyota. Veículos confiáveis para o campo e atendimento personalizado.`,
  },
  {
    pagina: "/venda-direta/taxista",
    title: `Descontos para Taxistas é na ${BRAND_NAMES.DEFAULT} Toyota`,
    description: `Taxistas têm condições diferenciadas na ${BRAND_NAMES.DEFAULT} Toyota. Veículos econômicos com descontos exclusivos.`,
  },
  {
    pagina: "/venda-direta/governo",
    title: `Soluções para Governo na ${BRAND_NAMES.DEFAULT} Toyota`,
    description: `Soluções em veículos Toyota para instituições governamentais. Durabilidade e eficiência que atendem às demandas do setor público.`,
  },

  {
    pagina: "/garantiatoyota",
    title: `Garantia Toyota de 10 Anos – Qualidade e Confiança`,
    description: `Conheça a Garantia Toyota de 10 anos. Mais segurança para aproveitar ao máximo seu veículo!`,
  },
  {
    pagina: "/servicos/consorcio",
    title: `Realize seu sonho do carro novo na ${BRAND_NAMES.DEFAULT} Toyota`,
    description: `Comprar ou trocar de carro é mais fácil com o Consórcio Toyota.`,
  },
  {
    pagina: "/servicos/blindagem",
    title: `Blindagem Toyota – Segurança com Qualidade Garantida`,
    description: `Proteja o que é importante com a ${BRAND_NAMES.DEFAULT} Toyota. Segurança e durabilidade com padrão de qualidade!`,
  },
  {
    pagina: "/servicos/pecas",
    title: `Peças Originais Toyota – Durabilidade e Garantia`,
    description: `Encontre peças originais Toyota na ${BRAND_NAMES.DEFAULT}. Garantia de qualidade e performance para o seu veículo!`,
  },
  {
    pagina: "/sobre",
    title: `História da ${BRAND_NAMES.DEFAULT} Toyota – Nossa Jornada de Sucesso`,
    description: `Conheça a história da ${BRAND_NAMES.DEFAULT} Toyota, uma trajetória marcada por excelência, inovação e compromisso com você!`,
  },
  {
    pagina: "/servicos/agendamento",
    title: `Fale Conosco – Serviço de Atendimento ${BRAND_NAMES.DEFAULT} Toyota`,
    description: `Precisa de ajuda? Entre em contato com o atendimento ao Consumidor da ${BRAND_NAMES.DEFAULT} Toyota. Estamos à disposição!`,
  },

  {
    pagina: "/kinto",
    title: `Kinto Toyota – Alugue um carro ou uma frota na ${BRAND_NAMES.DEFAULT}`,
    description: `Descubra as soluções de mobilidade KINTO na ${BRAND_NAMES.DEFAULT} Toyota. Alugue um carro para uso pessoal ou uma frota para sua empresa.`,
  },
];
