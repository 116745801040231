import { actualSite, lojaInterface } from "@/app/utils/utils";
import Image from "next/image";
import React, { useState } from "react";
import ContactInfo from "./ContactInfo";
import OperatingHours from "./OperatingHours";

export const Contact: React.FC = () => {
  const { lojas } = actualSite();
  const [loja, setLoja] = useState<lojaInterface | undefined>(lojas[0]);
  return (
    <div className="flex flex-col justify-center">
      <section className="flex relative flex-col bg-[#42474C]  w-full min-h-[382px] max-md:max-w-full">
        <div className="flex max-md:pb-80 relative z-10 p-8 flex-row justify-center items-end w-full max-md:pl-5 max-md:max-w-full">
          <Image
            width={400}
            height={400}
            loading="lazy"
            src="https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/fachada_toyota.webp"
            alt=""
            className="absolute inset-0 top-0 md:h-full max-md:mt-auto "
          />
          <div className="max-w-full ml-auto backdrop-blur-sm rounded-lg bg-black bg-opacity-30 w-[712px]">
            <div className="flex gap-5 max-md:flex-col">
              <ContactInfo setLoja={setLoja} loja={loja} />
              <OperatingHours loja={loja} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
