export interface siteInteface {
  siteName: string;
  siteLogoMenu: any;
  siteLogoFooter: any;
  siteDefaultName: string;
  siteAbout?: string;
  lojas: lojaInterface[];
}

export interface lojaInterface {
  nome: string;
  telefone: string;
  link: string;
  email: string;
  endereço: string;
  hShow: string;
  hService: string;
  hPecas: string;
}

export const LOJAS_DATA: lojaInterface[] = [
  {
    nome: "Vitória",
    telefone: "(27) 2125-2125",
    link: "https://goo.gl/maps/fRxynjU1x6zKp5GK6",
    email: "kurumavitoria@kurumaveiculos.com.br",
    endereço:
      "Av. Nossa Senhora da Penha, 2255 - Santa Luzia - Vitória - ES, CEP: 29.045-402",
    hShow: "Dias úteis: de 08h às 18:30h ,Sábados: 08h às 14h",
    hService: "Dias úteis: de 07:30h às 18h ,Sábados: 08h às 12h",
    hPecas: "Dias úteis: de 08h às 18h ,Sábado: 08h às 12h",
  },
  {
    nome: "Vila Velha",
    telefone: "(27) 2125-2222",
    link: "https://goo.gl/maps/H2gkD12vrUR9RXxM9",
    email: "kurumavilavelha@kurumaveiculos.com.br",
    endereço:
      "Rodovia do Sol, 215 - Praia de Itaparica, Vila Velha - ES, 29124-060",
    hShow: "Dias úteis: de 07:30 às 18:30h ,Sábados: 08h às 14h",
    hService:
      "Segunda: de 08h às 17:30h Terça a Sexta: 07:30h às 17:30h ,Sábado: 08h às 12h",
    hPecas: "Dias úteis: de 08h às 18h ,Sábados: 08h às 12h",
  },
  {
    nome: "Serra",
    telefone: "(27) 2125-5400",
    link: "https://goo.gl/maps/Re2wS8icEsm2Gr1E7",
    email: "recepcaokrmserra@kurumaveiculos.com.br",
    endereço:
      "Rod. BR-101 Norte, S/N - KM 265 - Planalto de Carapina, Serra - ES, 29162-702",
    hShow: "Dias úteis: de 08h às 18h ,Sábados: 08h às 14h",
    hService: "Dias úteis: de 07:30h às 17:30h ,Sábado: 08h às 12h",
    hPecas: "Dias úteis: de 08h às 18h ,Sábado: 08h às 12h",
  },
  {
    nome: "Cariacica",
    telefone: "(27) 2125-4900",
    link: "https://goo.gl/maps/NoM1C1rDiPycrc3z5",
    email: "",
    endereço:
      "Rodovia Governador Mario Covas, Km 294, Vila Independência, Cariacica /ES - CEP: 29148-640",
    hShow: "Dias úteis: de 08h às 18h ,Sábados: 08h às 14h",
    hService: "Dias úteis: de 08h às 18h ,Sábados: 08h às 12h",
    hPecas: "Dias úteis: de 08h às 18h ,Sábados: 08h às 12h",
  },
  {
    nome: "Guarapari",
    telefone: "(27) 3221-2500",
    link: "https://goo.gl/maps/hxVXG8ULZ9WNJxYK7",
    email: "",
    endereço:
      "Av. Padre José de Anchieta, 3275, Praia do Morro, CEP. 29.216-005",
    hShow: "Dias úteis: de 08h às 18h ,Sábados: 08h às 12h",
    hService: "Dias úteis: de 07:30h às 18h ,Sábado: 07:30h às 12h",
    hPecas: "Dias úteis: de 08h às 18h ,Sábado: 8h às 12h",
  },
  {
    nome: "Cachoeiro",
    telefone: "(28) 3322-3322",
    link: "https://goo.gl/maps/6UciXRDuDDxDKmYQ7",
    email: "kurumacachoeiro@kurumaveiculos.com.br",
    endereço:
      "Rodovia Engenheiro Fabiano Vivacqua, 31 a 45, Marbrasa, Cachoeiro de Itapemirim/ES - CEP: 29.313-656",
    hShow: "Dias úteis: de 08h às 18h ,Sábados: 08h às 12h",
    hService: "Dias úteis: de 07:30h às 17:30h ,Sábados: 08h às 12h",
    hPecas: "Dias úteis: de 08h às 18h ,Sábado: 08h às 12h",
  },
  {
    nome: "Colatina",
    telefone: "(27) 2101-6200",
    email: "kurumacolatina@kurumaveiculos.com.br",
    link: "https://goo.gl/maps/Nt8Nd1a17w32abULA",
    endereço: "Avenida Brasil, nº338, Lacê, Colatina -ES - CEP: 29703-032",
    hShow: "Dias úteis: de 08h às 18h ,Sábados: 08h às 12h",
    hService:
      "Segunda: de 08h às 17:30h Terça à Sexta:07:30h às 17:30h ,Sábado: 08h às 12h",
    hPecas: "Dias úteis: de 08h ás 18h ,Sábado: 08h às 12h",
  },
  {
    nome: "Linhares",
    telefone: "(27) 2103-9200",
    email: "kurumalinhares@kurumaveiculos.com.br",
    link: "https://goo.gl/maps/8ptTcN7nqjrQbUrC7",
    endereço:
      "Rua João Francisco Calmon, 925, Araça, Linhares, ES, CEP-29901420",
    hShow: "Dias úteis: de 08h às 18h ,Sábados: 08h às 13h",
    hService: "Dias úteis: de 07:30h às 17:30h ,Sábado: 08h às 12h",
    hPecas: "Dias úteis: de 08h às 18h ,Sábado: 08h às 12h",
  },
  {
    nome: "São Mateus",
    telefone: "(27) 3763-1010",
    link: "https://goo.gl/maps/tJkqRtd94qFqpcrx9",
    email: "",
    endereço: "Av.Rotari Clube, Bairro Sernamby, 633, 29931050",
    hShow: "Dias úteis: de 08h às 18h ,Sábados: 08h às 12h",
    hService: "Dias úteis: de 07:30h às 17:30h ,Sábados: 08h às 12h ",
    hPecas: " Dias úteis: de 07:30h às  17:30h Sábados: 08h às 12h",
  },
  {
    nome: "Venda Nova",
    telefone: "(27) 2133-0300",
    link: "https://goo.gl/maps/RMXw1TRYVyBM2Gv69",
    email: "",
    endereço: "Rodovia BR 262, S/N, Arace - Domingos Martins - ES, 29278-000",
    hShow: "Dias úteis: de 08h às 18h ,Sábados: 08h ás 12h",
    hService: "Dias úteis: de 08h as 18h ,Sábado: 08h ás 12h",
    hPecas: "Dias úteis: de 08h as 18h ,Sábado: 08h ás 12h",
  },
];

export const SITES_CONFIG = {
  KURUMA: {
    siteName: "KURUMA",
    siteDefaultName: "Kurumá",
    lojas: LOJAS_DATA,
    pathname: typeof window !== "undefined" ? window.location.pathname : "",
  },
} as const;

export const actualSite = () => {
  const siteName = process.env
    .NEXT_PUBLIC_SITE_NAME as keyof typeof SITES_CONFIG;
  return SITES_CONFIG[siteName] || SITES_CONFIG.KURUMA;
};

export function formatCarModel(model: string): string {
  return model
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function formatName(model: string): string {
  return model
    .toLocaleLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export const BRAND_NAMES = {
  SEMINOVOS_SITE_URL: "https://www.kurumaseminovos.com.br",
  SEMINOVOS_CERTIFICADO_URL:
    "https://www.kurumaseminovos.com.br/veiculos?certificado=true&ano=2018,2025&preco=4390000,42499000&km=101,81193&entrada=12749700&ordenacao=1",
  CHAT_INSTANCE_ID: "SITE_KURUMA",
  CHAT_API_KEY: "7F9B3C1D2A454E8C8A5692EF4D93B837F9B3C1D2A454E8C",
  POS_NUMBER: "552730032626",
  NORMAL_NUMBER: "5527998497670",
  STATE: "Espírito Santo",
  STATE_RED: "no Espírito Santo",
  SUB_BRAND: "8",
  DEFAULT: "Kurumá",
  UPPERCASE: "KURUMÁ",
  LOWERCASE: "kurumá",
  LOWERCASE_PLAIN: "kuruma",
  ALL_CAPS: "KURUMA",
  CAMELCASE: "Kuruma",
  DISPLAY: "Kurumá Veículos",
  LEGAL: "Kurumá Veículos Toyota",
  SHORT: "KRM",
  URL: "kurumaveiculos",
  META_TITLE: "Kurumá Toyota",
  EMAIL_DOMAIN: "@kurumaveiculos.com.br",
  NUMERO_POS_VENDA: "2730032626",
  NUMERO_POS_VENDA_TRATADO: "(27) 3003-2626",
  siteLogoMenu:
    "https://sablobsitestoyotaprd.blob.core.windows.net/images/logo_menu_kuruma.svg",
  ABOUT: `Em 1992, o cenário das importações de veículos no Brasil abriu
                caminho para a criação de uma concessionária própria da
                prestigiada montadora japonesa Toyota no Espírito Santo. Foi
                nesse ano que a Kurumá nasceu, com a inauguração de sua primeira
                loja em Cariacica, assumindo a revenda exclusiva de automóveis
                Toyota e Lexus na Grande Vitória. Hoje, a Kurumá está presente
                em diversas cidades do Espírito Santo, incluindo Vitória, Vila
                Velha, Serra, Cariacica, Guarapari, Linhares, Colatina,
                Cachoeiro de Itapemirim, São Mateus e Venda Nova do Imigrante.
                Nossa expansão reflete o compromisso em levar a qualidade Toyota
                a cada vez mais clientes. As concessionárias Toyota do Grupo
                Águia Branca seguem rigorosamente os padrões globais da
                montadora, com o propósito de proporcionar aos nossos clientes
                uma experiência de compra e posse inigualável no mercado
                automotivo. Há mais de 78 anos, o Grupo Águia Branca tem sido
                sinônimo de excelência, inovação e expansão contínua. Com
                presença nacional, o grupo se destaca em setores estratégicos
                como transporte de passageiros, logística e comércio de
                veículos. Somos o maior representante da Toyota na América
                Latina e o maior grupo de concessionárias Lexus no Brasil.
                Contamos com mais de 100 concessionárias espalhadas pelo país e
                a força de mais de 20 mil colaboradores, com certificação GPTW,
                movidos pela paixão de oferecer o melhor em todas as nossas
                operações. Como representantes da Toyota em três estados —
                Distrito Federal, Minas Gerais e Espírito Santo —, temos orgulho
                de honrar diariamente o compromisso com a qualidade e
                confiabilidade, valores que definem a Toyota mundialmente. Nossa
                jornada é impulsionada pela paixão em conectar pessoas e
                oportunidades, sempre oferecendo produtos e serviços de
                excelência.`,
} as const;

// Tipo utilitário para acessar as chaves
export type BrandNameKeys = keyof typeof BRAND_NAMES;
