import React, { createContext, useState, ReactNode, useEffect } from "react";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { AuthContextType, AuthState } from "../interface/components";

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [auth, setAuth] = useState<AuthState>({
    authToken: "",
    isAuthenticated: false,
  });

  const router = useRouter();

  useEffect(() => {
    const authToken = Cookies.get("authToken");
    if (authToken) {
      setAuth({ authToken, isAuthenticated: true });
    }
  }, []);

  const login = (authToken: string) => {
    const expirationTime = 1; // 1 day
    Cookies.set("authToken", authToken, {
      expires: expirationTime,
      sameSite: "Strict",
      secure: true,
    });
    setAuth({ authToken, isAuthenticated: true });
  };

  const logout = () => {
    Cookies.remove("authToken");
    setAuth({ authToken: "", isAuthenticated: false });
    router.push("/cms?key=" + process.env.NEXT_PUBLIC_CMS_KEY);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const authToken = Cookies.get("authToken");
      if (!authToken) {
        logout();
        window.location.reload();
      }
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
