import { useRouter } from "next/router";
import Link from "next/link";
import { ReactNode, AnchorHTMLAttributes, useMemo } from "react";
import React from "react";

interface CustomLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;
  children: ReactNode;
}

const CustomLink: React.FC<CustomLinkProps> = ({
  href,
  children,
  ...props
}) => {
  const router = useRouter();

  const combinedHref = useMemo(() => {
    const [baseURL, hashFragment] = href.split("#");
    const [path, newQueryString] = baseURL.split("?");

    // Parâmetros da URL atual que queremos manter (UTMs)
    const currentParams = new URLSearchParams(
      router.query as Record<string, string>
    );
    const utmParams = new URLSearchParams();

    // Lista de parâmetros UTM que queremos manter
    const utmKeys = [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_term",
      "utm_content",
    ];

    // Manter apenas os UTMs da URL atual
    utmKeys.forEach((key) => {
      const value = currentParams.get(key);
      if (value) utmParams.set(key, value);
    });

    // Adicionar os novos parâmetros da href
    const newParams = new URLSearchParams(newQueryString || "");

    // Combinar UTMs com os novos parâmetros
    const finalParams = new URLSearchParams(newParams.toString());
    utmParams.forEach((value, key) => {
      finalParams.set(key, value);
    });

    // Construir a URL final
    const queryStringFinal = finalParams.toString();
    let hrefWithParams = queryStringFinal
      ? `${path}?${queryStringFinal}`
      : path;

    if (hashFragment) {
      hrefWithParams += `#${hashFragment}`;
    }

    return hrefWithParams;
  }, [href, router.query]);

  return (
    <Link href={combinedHref} {...props}>
      {children}
    </Link>
  );
};

export default React.memo(CustomLink);
